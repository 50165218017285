<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Hostname</th>
                <td colspan="2">
                  {{ asup.hostname }}
                </td>
              </tr>
              <tr>
                <th>Version</th>
                <td colspan="2">
                  {{ asup.version }}
                </td>
              </tr>
              <tr>
                <th>Serial</th>
                <td colspan="2">
                  {{ asup.serial }}
                </td>
              </tr>
              <tr>
                <th>Edition</th>
                <td colspan="2">
                  {{ asup.details.license.edition }}
                </td>
              </tr>
              <tr v-if="asup.details.license.expirationDate">
                <th>License expiration</th>
                <td colspan="2">
                  {{ asup.details.license.expirationDate | formatDate }}
                </td>
              </tr>
              <tr v-if="asup.details.license.supportExpirationDate">
                <th>Support expiration</th>
                <td colspan="2">
                  {{ asup.details.license.supportExpirationDate | formatDate }}
                </td>
              </tr>

              <tr>
                <th>System Timezone</th>
                <td colspan="2">
                  {{ asup.timezone }}
                </td>
              </tr>
              <tr>
                <th>Last autosupport data</th>
                <td colspan="2">
                  {{ asup.updateTimestampUtc | formatDateTime }}
                </td>
              </tr>
              <tr v-if="asup.updateTimestampUtc > asup.lastBuildModelTimestamp">
                <th>Model built on (UTC)</th>
                <td colspan="2">
                  {{ asup.lastBuildModelTimestamp | formatDateTime }}<br>
                  <small class="text-hint text-danger">The displayed data is older than the latest Autosupport information. The displayed data should be updated a few minutes after the latest Autosupport data.</small>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>

      </b-col>
      <b-col>
        <b-card>
          <template #header>
            <h4 class="card-title">
              Monitor Status <small class="text-muted">({{ monitors.length }} monitors checked)</small>
            </h4>
            <div
              v-if="isMonitorOverwritten"
              class="float-right"
            >
              <b-badge
                v-b-popover.hover="'At least one monitor has been overwritten for this system.'"
                pill
                variant="info"
              >
                <small>Monitor overwritten <feather-icon icon="HelpCircleIcon" /></small>
              </b-badge>
            </div>
          </template>

          <ul
            v-if="activeMonitors.length > 0"
            style="list-style: none"
          >
            <li
              v-for="monitor in activeMonitors"
              :id="`monitor_${monitor.monitorId}`"
              :key="monitor.monitorId"
            >
              <monitor-status-icon
                :status="monitor.status"
                :text="monitor.shortMessage || monitor.exceptionMessage"
              />

              <b-popover
                custom-class="wide-popover"
                :target="`monitor_${monitor.monitorId}`"
                triggers="hover"
                :variant="popoverVariant(monitor.status)"
              >
                <template #title>
                  Detailed message
                </template>

                <span
                  v-if="monitor.message"
                  v-html="monitor.message"
                />
                <span v-else>No detailed message</span>
              </b-popover>
            </li>
          </ul>
          <div v-else>
            <monitor-status-icon
              :status="0"
              text="All monitors are OK"
            />
          </div>
        </b-card>

        <b-card>
          <template #header>
            <h4 class="card-title">
              Services
            </h4>
          </template>

          <table class="table table-sm">
            <thead>
              <tr>
                <th>Status</th>
                <th>Service</th>
                <th>Start Type</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="service in orderedServices"
                :key="service.displayName"
              >
                <td>
                  <b-badge :variant="getServiceStatusBadgeVariant(service.status)">
                    {{ service.status }}
                  </b-badge>
                </td>
                <td>{{ service.displayName }}</td>
                <td>{{ service.startType }}</td>
              </tr>
            </tbody>
          </table>

        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, VBPopover, BPopover, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'

import AsupStatusService from '@/service/asupStatus.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    MonitorStatusIcon,
    BPopover,
    BBadge,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      monitors: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    activeMonitors() {
      return this.monitors.filter(x => x.status !== 0)
    },
    isMonitorOverwritten() {
      if (this.isHost && this.asup.isHostMonitorOverwritten) {
        return true
      }
      if (!this.isHost && this.asup.isTenantMonitorOverwritten) {
        return true
      }

      return false
    },
    orderedServices() {
      if (this.asup.details && this.asup.details.services) {
        return this.asup.details.services.concat().sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
      }

      return []
    },
  },
  created() {
    AsupStatusService.getActiveListAsync({ asupSystemId: this.asup.id })
      .then(result => {
        this.monitors = result.items
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    getServiceStatusBadgeVariant(serviceStatus) {
      if (serviceStatus === 'Running') {
        return 'success'
      }

      return 'danger'
    },
    popoverVariant(status) {
      switch (status) {
        case 0: return 'success'
        case 10: return 'info'
        case 20: return 'warning'
        case 30:
          return 'danger'
        default: return 'danger'
      }
    },
  },
}
</script>

<style scoped>
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
